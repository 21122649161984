
import _this from "../../main";
import router from '../../router'
import Api from '../../api/index.js';
export default {
    install(Vue) {
        Vue.prototype.loadconfig = function (isLoad,callback){
					if(isLoad){
						Api.changeColor().then(res=>{
							if(res.data.status){
								let getChangeColor = localStorage.getItem('changeColor')?localStorage.getItem('changeColor'):false;
								// if(getChangeColor != res.data.data){
									Api.config().then(data=>{
										if(data.data.status){
											this.$store.commit('setConfig', data.data.data);
											localStorage.setItem('sysConfig',JSON.stringify(data.data.data));
											localStorage.setItem('title',data.data.data.baseConfig.webTitle)
											localStorage.setItem('lunbo',data.data.data.baseConfig.imgWebUrl)
											localStorage.setItem('upload',data.data.data.baseConfig.sp_Type_DefPic)
											localStorage.setItem('base',JSON.stringify(data.data.data.baseConfig))
											localStorage.setItem('tmpTheme', JSON.stringify(data.data.data.colorConfig));
											this.$store.commit('updateTheme', data.data.data.colorConfig);
											callback&&callback(data.data.data);
										} 
									})
								// }

								localStorage.setItem('changeColor',res.data.data)
							} 
						})
					} else {
						let sysConfigStr = localStorage.getItem('sysConfig');
						if(sysConfigStr){
								let sysConfig = JSON.parse(sysConfigStr)

								this.$store.commit('setConfig', sysConfig);
								localStorage.setItem('sysConfig',JSON.stringify(sysConfig));
								localStorage.setItem('title',sysConfig.baseConfig.webTitle)
								localStorage.setItem('lunbo',sysConfig.baseConfig.imgWebUrl)
								localStorage.setItem('upload',sysConfig.baseConfig.sp_Type_DefPic)
								localStorage.setItem('base',JSON.stringify(sysConfig.baseConfig))
								localStorage.setItem('tmpTheme', JSON.stringify(sysConfig.colorConfig));
								this.$store.commit('updateTheme', sysConfig.colorConfig);
								callback&&callback(sysConfig);
						}else{
								Api.config().then(res=>{
										if(res.data.status){
												this.$store.commit('setConfig', res.data.data);
												localStorage.setItem('sysConfig',JSON.stringify(res.data.data));
												localStorage.setItem('title',res.data.data.baseConfig.webTitle)
												localStorage.setItem('lunbo',res.data.data.baseConfig.imgWebUrl)
												localStorage.setItem('upload',res.data.data.baseConfig.sp_Type_DefPic)
												localStorage.setItem('base',JSON.stringify(res.data.data.baseConfig))
												localStorage.setItem('tmpTheme', JSON.stringify(res.data.data.colorConfig));
												this.$store.commit('updateTheme', res.data.data.colorConfig);
												callback&&callback(res.data.data);
										}
								})
						}
					}
        };
				//根据sessionKey获取用户信息 全局函数
				Vue.prototype.getCurrentUserInfo = function (userinfosessionKey, redirect, callback){

					redirect = redirect ? decodeURIComponent(redirect) : undefined;
					Api.getCurrentUserInfo({token: userinfosessionKey}).then(res=>{
		
						// console.log(res);
		
						if( res.status == 200 ){
							if( typeof res.data != "object" ){
								try{
									res.data = JSON.parse(res.data);
								} catch(error) {
									// _this.$router.replace({ path: '/login', query: { redirect: redirect }});
									console.error(error);
									return false;
								}
							}
							if( res.data.status ){
								const fetch_data = res.data.data;
								//console.log(fetch_data);
								
								let user_data = {
									img: fetch_data.headImageUrl,
									isShowPrint: fetch_data.isShowPrint,
									token: this.$cookie.get('token'),
									userName: fetch_data.userName,
								}
		
								//本地存储
								localStorage.setItem('buyerInfo', JSON.stringify(user_data));//session
		
								//读取
								//console.log( localStorage.getItem('user_data') );
								//console.log( JSON.parse(localStorage.getItem('user_data')) );
		
								callback && callback();
		
								if( redirect ){
									location.replace(redirect);
									if( redirect != './' ){
										//location.reload();
									}
								}
		
							} else {
								console.error('自动登录失败');
								_this.$router.replace({ path: '/login', query: { redirect: redirect }});
							}
		
						} else {
							console.error(res.data.msg ? res.data.msg : '自动登录失败——2');
							_this.$router.replace({ path: '/login', query: { redirect: redirect }});
						}
		
					}).catch(function (error) {
						_this.$router.replace({ path: '/login', query: { redirect: redirect }});
						console.error(error);
					});
				}
        //行为埋点
				Vue.prototype.setBehaviorPoint = function (json){
					//return false;//埋点先关掉
					//浏览器
					var browser = ''
					var userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串
					console.log(userAgent)
					var isOpera = userAgent.indexOf("Opera") > -1;
					if (isOpera) {//判断是否Opera浏览器
						browser= "Opera"
					}; 
					if (userAgent.indexOf("Firefox") > -1) {//判断是否Firefox浏览器
						browser= "FF";
					} 
					if (userAgent.indexOf("Chrome") > -1){//判断是否Chrome浏览器
						browser= "Chrome";
					}
					if (userAgent.indexOf("Safari") > -1&&userAgent.indexOf("Chrome")==-1) {//判断是否Safari浏览器
						browser= "Safari";
					} 
					if (userAgent.indexOf("Edg") > -1) {//判断是否微软浏览器
						browser= "Edg";
					} 
					if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {//判断是否IE浏览器
						browser= "IE";
					}; 
					
					let tmpParams = {
						ViewPath: '',
						ViewName: '',
						Timer: '',
						BrowserType: browser,
					}
					tmpParams = Object.assign(tmpParams, json);
					//_this.$toast(tmpParams)
					Api.AddBuyerAct(tmpParams).then(res=>{

					})
				};
				Vue.prototype.NumberMul = function(arg1, arg2) {
						var m = 0;
						var s1 = arg1.toString();
						var s2 = arg2.toString();
						try {
								m += s1.split(".")[1].length;
						} catch (e) {}
						try {
								m += s2.split(".")[1].length;
						} catch (e) {}
				
						return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
				};
				Vue.prototype.NumberDiv = function (arg1,arg2,digit){
						var t1=0,t2=0,r1,r2;
						try{t1=arg1.toString().split(".")[1].length}catch(e){}
						try{t2=arg2.toString().split(".")[1].length}catch(e){}
						r1=Number(arg1.toString().replace(".",""))
						r2=Number(arg2.toString().replace(".",""))
						//获取小数点后的计算值
					var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
						var result2=result.split(".")[1];
						result2=result2.substring(0,digit>result2.length?result2.length:digit);
				
						return Number(result.split(".")[0]+"."+result2);
				}
				Number.prototype.toFixed=function (d) { 
					var s=this+""; 
					if(!d)d=0; 
					if(s.indexOf(".")==-1)s+="."; 
					s+=new Array(d+1).join("0"); 
					if(new RegExp("^(-|\\+)?(\\d+(\\.\\d{0,"+(d+1)+"})?)\\d*$").test(s)){
						 var s="0"+RegExp.$2,pm=RegExp.$1,a=RegExp.$3.length,b=true;
						 if(a==d+2){
								 a=s.match(/\d/g); 
								 if(parseInt(a[a.length-1])>4){
										 for(var i=a.length-2;i>=0;i--){
												 a[i]=parseInt(a[i])+1;
												 if(a[i]==10){
														 a[i]=0;
														 b=i!=1;
												 }else break;
										 }
								 }
								 s=a.join("").replace(new RegExp("(\\d+)(\\d{"+d+"})\\d$"),"$1.$2");

						 }if(b)s=s.substr(1); 
						 return (pm+s).replace(/\.$/,"");
				}return this+"";

		 };
  }
}