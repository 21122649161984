// import 'babel-polyfill'
// import Es6Promise from 'es6-promise'
// require('es6-promise').polyfill()
// Es6Promise.polyfill(); Text
// import {createApp} from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import qs from 'qs'
// Vue.use(qs)
// import $ from './assets/js/jquery.min.js'
import jquery from "jquery";
Vue.prototype.$ = jquery;
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import Api from './api/index.js';
Vue.prototype.$api = Api;
Vue.prototype.$sets=new Vue();

import Site from './assets/js/site.js'
Vue.use(Site);
//图片滑动验证功能
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import Cookies from "js-cookie";
Vue.prototype.$cookie = Cookies;

Vue.config.productionTip = false

//Vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import { Lazyload } from 'vant';
Vue.use(Lazyload,{
    observer:true
});

// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

// import VCharts from 'v-charts'
// import 'v-charts/lib/style.css'
// Vue.use(VCharts)

import histogram from 'v-charts/lib/histogram.common'
Vue.component(histogram.name, histogram)
import line from 'v-charts/lib/line.common'
Vue.component(line.name, line)

import md5 from 'js-md5'
import moment from 'moment'
Vue.prototype.$md5 = md5;
Vue.prototype.$moment = moment;

//前置守卫
router.beforeEach((to, from, next) => {
    if(!Cookies.get('token')){
        localStorage.clear();
    }
    //刷新token
    let starttime = localStorage.getItem('nowtime');
    let endtime = new Date().getTime();
    let IsForget = localStorage.getItem('IsForget');
    // if(starttime&&((endtime-starttime) > 5400000)){
    if(!IsForget&&starttime&&((endtime-starttime) > 604800000)){
        var url = axios.defaults.baseURL + 'api/user/replaceToken'
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + Cookies.get('token')
            },
            data:{
                isForget:localStorage.getItem('IsForget')?1:0
            }
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            if(data.status){
                let token = data.data;
                localStorage.setItem('token',token);
                Cookies.set('token',token);
                localStorage.setItem('nowtime',endtime);
                
            }
            
            
        });
    }
   //超过一周退出
   if(!localStorage.getItem('IsForget')&&starttime&&((endtime-starttime) > 604800000)){ 
        localStorage.clear();
    }

    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    next();
});

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
